<template>
  <ion-page ref="singlePelicula">
    <ion-header class="ion-no-border">
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-back-button color="primary" @click="$router.back()" text=""></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="false">
      <!-- COVER -->
      <div class="cover" v-if="!isLoading">
        <div class="background-image" :style="movie.portada
            ? {
              backgroundImage:
                'url(' + movie.portada.data.thumbnails[0].url + ')',
            }
            : {
              backgroundImage:
                'url(/assets/img/portada_no_disponible.jpg)',
            }
          "></div>
        <div class="main-image" :style="movie.portada
            ? {
              backgroundImage:
                'url(' + movie.portada.data.thumbnails[5].url + ')',
            }
            : {
              backgroundImage:
                'url(/assets/img/portada_no_disponible.jpg)',
            }
          " />
        <div class="text">
          <div class="date-tag" v-if="movie.fecha_local != null">
            {{ movie.fecha_local }}
          </div>
          <h2>{{ movie.titulo }}</h2>
          <div class="genre-tags" v-if="movie.genero && movie.genero.length > 0">
            <div class="genre-tag" v-for="(genreTag, genreIndex) in movie.genero" :key="genreIndex.id">
              <span>{{ genreTag.generos_id.nombre }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- COVER SKELETON -->
      <div class="cover loading" v-else></div>

      <!-- INFO DEKSTOP -->
      <div class="container ion-hide-sm-down">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="8">
              <!-- Sinopsis -->
              <section class="sinopsis">
                <div class="section-title">Sinopsis</div>
                <div class="sinopsis" v-if="movie.sinopsis" v-html="movie.sinopsis"></div>
                <div v-else>
                  <div class="custom-skeleton">
                    <p v-for="(n, index) in 5" :key="index">
                      <ion-skeleton-text animated></ion-skeleton-text>
                    </p>
                    <p>
                      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    </p>
                  </div>
                </div>
              </section>

              <!-- Ficha Técnica -->
              <section class="ficha">
                <div class="section-title">Ficha Técnica</div>
                <div v-if="movie.sinopsis">
                  <p><b>Elenco:</b> {{ movie.elenco }}</p>
                  <p><b>Dirigida por:</b> {{ movie.dirigida_por }}</p>
                  <p v-if="movie.distribuidora">
                    <b>Distribuidora:</b> {{ movie.distribuidora.nombre }}
                  </p>
                  <p><b>Duración:</b> {{ movie.duracion }}</p>
                  <p><b>Calificación:</b> {{ movie.calificacion }}</p>
                  <div v-if="movie.codigos">
                    <p v-for="(value, index) in Object.keys(movie.codigos).map(
                      (key) => [String(key), movie.codigos[key]]
                    )" :key="index">
                      <b>Código INCAA {{ value[0] }}:</b> {{ value[1] }}
                    </p>
                    <p><b>Porcentajes:</b> {{ movie.porcentaje }}</p>
                  </div>
                </div>
                <div v-else>
                  <div class="custom-skeleton">
                    <p v-for="(n, index) in 5" :key="index">
                      <ion-skeleton-text animated></ion-skeleton-text>
                    </p>
                    <p>
                      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    </p>
                  </div>
                </div>
              </section>
            </ion-col>

            <ion-col size="4">
              <!-- Descargas -->
              <section class="descargas">
                <div class="section-title">Descargas</div>
                <div>
                  En esta sección podrás encontrar todo lo enviado hasta la
                  fecha: afiches previo y/o definitivo, arte para redes
                  sociales, spot de radio si hubiera, etc.
                </div>

                <section v-if="movie.materiales && movie.materiales.length > 0">
                  <ion-list>
                    <a :href="material.link" target="blank" v-for="material in movie.materiales" :key="material.id">
                      <ion-item class="ion-no-padding">
                        <ion-label><b>{{ material.tipo }}</b></ion-label>
                        <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
                      </ion-item>
                    </a>
                  </ion-list>
                </section>

                <section v-else>
                  <b>De momento no hay materiales. Pronto los encontrarás
                    disponibles aquí.</b>
                </section>
              </section>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <!-- Mobile -->
      <div class="segment ion-hide-sm-up">
        <ion-segment mode="md" value="informacion" @click="segmentChanged($event.target._value)">
          <ion-segment-button value="informacion">
            <ion-label>Información</ion-label>
          </ion-segment-button>
          <ion-segment-button value="materiales">
            <ion-label>Materiales</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div id="container">
          <div v-show="segment == 'informacion'">
            <!-- Sinopsis -->
            <div class="container">
              <section class="sinopsis">
                <div class="section-title">Sinopsis</div>
                <div class="sinopsis" v-if="movie.sinopsis" v-html="movie.sinopsis"></div>
                <div v-else>
                  <div class="custom-skeleton">
                    <p v-for="(n, index) in 5" :key="index">
                      <ion-skeleton-text animated></ion-skeleton-text>
                    </p>
                    <p>
                      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    </p>
                  </div>
                </div>
              </section>

              <!-- Ficha Técnica -->
              <section class="ficha">
                <div class="section-title">Ficha Técnica</div>
                <div v-if="movie.sinopsis">
                  <p><b>Elenco:</b> {{ movie.elenco }}</p>
                  <p><b>Dirigida por:</b> {{ movie.dirigida_por }}</p>
                  <p v-if="movie.distribuidora">
                    <b>Distribuidora:</b> {{ movie.distribuidora.nombre }}
                  </p>
                  <p><b>Duración:</b> {{ movie.duracion }}</p>
                  <p><b>Calificación:</b> {{ movie.calificacion }}</p>
                  <div v-if="movie.codigos">
                    <p v-for="(value, index) in Object.keys(movie.codigos).map(
                      (key) => [String(key), movie.codigos[key]]
                    )" :key="index">
                      <b>Código INCAA {{ value[0] }}:</b> {{ value[1] }}
                    </p>
                    <p><b>Porcentajes:</b> {{ movie.porcentajes }}</p>
                  </div>
                </div>
                <div v-else>
                  <div class="custom-skeleton">
                    <p v-for="(n, index) in 5" :key="index">
                      <ion-skeleton-text animated></ion-skeleton-text>
                    </p>
                    <p>
                      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div v-show="segment == 'materiales'">
            <div class="container">
              <!-- Descargas -->
              <section class="descargas">
                <div class="section-title">Descargas</div>
                <div>
                  En esta sección podrás encontrar todo lo enviado hasta la
                  fecha: afiches previo y/o definitivo, arte para redes
                  sociales, spot de radio si hubiera, etc.
                </div>

                <section v-if="movie.materiales && movie.materiales.length > 0">
                  <ion-list>
                    <a :href="material.link" target="blank" v-for="material in movie.materiales" :key="material.id">
                      <ion-item class="ion-no-padding">
                        <ion-label><b>{{ material.tipo }}</b></ion-label>
                        <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
                      </ion-item>
                    </a>
                  </ion-list>
                </section>

                <section v-else>
                  <b>De momento no hay materiales. Pronto los encontrarás
                    disponibles aquí.</b>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonSkeletonText,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonItem,
} from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pelicula",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonSkeletonText,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonList,
    IonItem,
  },
  data() {
    return {
      server: "https://admin.enelcine.com.ar/api",
      isLoading: false,
      movie: [] as any[any],
      segment: "informacion",
    };
  },
  setup() {
    return {
      chevronForwardOutline,
    };
  },
  mounted() {
    this.loadSingle();
  },
  methods: {
    async loadSingle() {
      this.isLoading = true;
      this.segment = "informacion";
      const slug = this.$route.params.slug;
      this.movie = await this.getItem(slug);
      this.isLoading = false;
    },
    async getItem(slug: any) {
      const endpoint =
        "/items/peliculas?fields=*,portada.*,poster.*,distribuidora.*,genero.generos_id.*,materiales.*&order=fecha_local&filter[slug]=" +
        slug;

      const response = await this.axios.get(
        this.server +
        endpoint +
        "&access_token=" +
        (this as any).$store.state.token
      );

      /*.catch(error=>{
        console.log(error.response)
        router.push('/disconnected')
        throw 'Error de Servidor'
      })*/

      if (!response.data.data[0]) {
        return [];
      } else {
        response.data.data[0].titulo =
          response.data.data[0].titulo_local != null
            ? response.data.data[0].titulo_local
            : response.data.data[0].titulo_original;
      }

      return response.data.data[0];
    },
    segmentChanged: function (value: any) {
      this.segment = value;
    },
  },
  watch: {
    $route(/*to, from*/) {
      this.loadSingle();
    },
  },
});
</script>

<style scoped>
.date-tag {
  min-width: 70px;
  min-height: 30px;
  background-color: #fcaf17;
  font-weight: 700;
  font-size: 12px;
  padding: 6px;
  text-transform: uppercase;
  width: max-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.genre-tags {
  display: flex;
  color: black;
  margin-bottom: 24px;
  overflow-x: auto;
}

.genre-tag {
  background: #fcaf17;
  border-radius: 32px;
  padding: 3px 12px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.genre-tag span {
  font-size: 14px;
  width: max-content;
}

ion-list {
  --ion-background-color: transparent !important;
}

ion-list ion-icon {
  margin-inline-end: 0;
}

@media (max-width: 768px) {
  .background-image {
    display: none;
  }
}
</style>