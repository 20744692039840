
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonSkeletonText,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonItem,
} from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pelicula",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonSkeletonText,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonList,
    IonItem,
  },
  data() {
    return {
      server: "https://admin.enelcine.com.ar/api",
      isLoading: false,
      movie: [] as any[any],
      segment: "informacion",
    };
  },
  setup() {
    return {
      chevronForwardOutline,
    };
  },
  mounted() {
    this.loadSingle();
  },
  methods: {
    async loadSingle() {
      this.isLoading = true;
      this.segment = "informacion";
      const slug = this.$route.params.slug;
      this.movie = await this.getItem(slug);
      this.isLoading = false;
    },
    async getItem(slug: any) {
      const endpoint =
        "/items/peliculas?fields=*,portada.*,poster.*,distribuidora.*,genero.generos_id.*,materiales.*&order=fecha_local&filter[slug]=" +
        slug;

      const response = await this.axios.get(
        this.server +
        endpoint +
        "&access_token=" +
        (this as any).$store.state.token
      );

      /*.catch(error=>{
        console.log(error.response)
        router.push('/disconnected')
        throw 'Error de Servidor'
      })*/

      if (!response.data.data[0]) {
        return [];
      } else {
        response.data.data[0].titulo =
          response.data.data[0].titulo_local != null
            ? response.data.data[0].titulo_local
            : response.data.data[0].titulo_original;
      }

      return response.data.data[0];
    },
    segmentChanged: function (value: any) {
      this.segment = value;
    },
  },
  watch: {
    $route(/*to, from*/) {
      this.loadSingle();
    },
  },
});
